import { ReactComponent as KeyboardIcon } from 'assets/images/Keyboard-icon.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, LinearProgress, Typography, alpha } from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { setIsRecording, setMode } from '../../../redux/chat/chat.slice';
import socket from '../../../services/socket';
import { ChatEvent } from '../enums/ChatEvent.enum';

const ChatInputVoice = ({ disabled }: { disabled: boolean }) => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useDispatch();

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const { isRecording } = useSelector(chatSelector);
  const [progressBar, setProgressBar] = useState(0);
  const progressRef = useRef<number>(0);

  useEffect(() => {
    if (isRecording) {
      progressRef.current = 0;
      setProgressBar(0);

      const timer = setInterval(() => {
        progressRef.current += 2; // speed of progress bar
        if (progressRef.current >= 100) {
          handleStopRecording();
          clearInterval(timer);
        } else {
          setProgressBar(progressRef.current);
        }
      }, 100);

      return () => {
        clearInterval(timer);
        setProgressBar(0);
      };
    }

    if (!isRecording && mediaRecorder) {
      setProgressBar(0);
      mediaRecorder.stop();
    }
  }, [isRecording]);

  const handleStartRecording = async () => {
    if (isRecording) return handleStopRecording();

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const recorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
    setMediaRecorder(recorder);

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        socket.emit(ChatEvent.USER_AUDIO_MESSAGE, event.data);
      }
    };

    recorder.start(1000);
    dispatch(setIsRecording(true));
  };

  const handleStopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
    }

    socket.emit(ChatEvent.USER_AUDIO_MESSAGE, undefined);
    dispatch(setIsRecording(false));
  };

  const handleSwitchMode = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setMediaRecorder(null);
      mediaRecorder.ondataavailable = null;
    }
    if (isRecording) {
      dispatch(setIsRecording(!isRecording));
      socket.emit(ChatEvent.USER_AUDIO_MESSAGE, 'cancel');
    }

    dispatch(setMode('text'));
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingX: isLargeScreen ? 0 : '4rem'
      }}>
      <Box
        sx={{
          width: '100%',
          maxWidth: '25rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '1rem'
        }}>
        <Button
          fullWidth
          disabled={disabled}
          onClick={handleStartRecording}
          sx={{
            fontSize: !isLargeScreen ? '1rem' : '1.125rem',
            boxShadow: `0px 1px 1px 1px ${alpha(Colors.primary.dark, 0.1)}`,
            borderRadius: '3rem',
            background: isRecording ? Colors.green.main : 'white',
            border: 'none',
            color: isRecording ? Colors.white : Colors.neutralGrey,
            fontFamily: 'Nunito',
            fontWeight: 500,
            height: isLargeScreen ? '5.25rem' : '3.75rem',
            position: 'relative',
            overflow: 'hidden',
            paddingLeft: isRecording ? '0.75rem' : '1rem',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none'
            },
            '&:active': {
              backgroundColor: isRecording ? Colors.green.main : 'white',
              boxShadow: `0px 1px 1px 1px ${alpha(Colors.primary.dark, 0.1)}`
            },
            '&:hover': {
              backgroundColor: isRecording ? Colors.green.main : 'white',
              boxShadow: `0px 1px 1px 1px ${alpha(Colors.primary.dark, 0.1)}`
            }
          }}>
          {isRecording ? (
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontFamily: 'Nunito',
                fontWeight: 600,
                gap: '0.75rem',
                zIndex: 40,
                fontSize: !isLargeScreen ? '1rem' : '1.125rem'
              }}>
              {!isLargeScreen && (
                <MoreHorizIcon sx={{ height: 25, width: 25 }} />
              )}
              Listening
            </Typography>
          ) : (
            'Tap to speak'
          )}
          {isRecording && (
            <LinearProgress
              variant="determinate"
              value={progressBar}
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '110%',
                height: '100%',
                backgroundColor: Colors.green.main,
                '& .MuiLinearProgress-bar': {
                  backgroundColor: Colors.green.medium
                },
                zIndex: 1
              }}
            />
          )}
        </Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.1)',
            borderRadius: '50%',
            padding: isLargeScreen ? '1rem' : 0,
            zIndex: 30
          }}>
          <Button
            onClick={handleSwitchMode}
            sx={{
              height: '3.375rem',
              width: '3.375rem',
              minWidth: '3.375rem',
              background: Colors.primary.dark,
              padding: 0,
              borderRadius: '50%',
              '&:hover': {
                background: Colors.green.main
              }
            }}>
            <KeyboardIcon
              style={{ width: '1.5rem', height: '1.5rem', padding: 0 }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatInputVoice;
