import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { ChatMessage } from '../types/chat-message.type';
import StatusAvatarPicture from './StatusAvatarPicture';

const AudioChatBox = () => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useIsLargeScreen();
  const { messages, isRecording } = useSelector(chatSelector);
  const [lastMessage, setLastMessage] = useState<ChatMessage | null>(null);

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].from === 'ai') {
      setLastMessage(messages[messages.length - 1]);
    }
  }, [messages]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView();
  }, [lastMessage, isRecording]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        paddingTop: isLargeScreen ? '5.375rem' : 0
      }}>
      {!isLargeScreen && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            paddingY: '4.125rem'
          }}>
          <StatusAvatarPicture sx={{ height: 147, width: 147 }} />
        </Box>
      )}
      <Box
        sx={{
          position: 'absolute',
          top: isLargeScreen ? '5.375rem' : '17.25rem',
          width: '100%',
          height: isLargeScreen ? '3rem' : '1.5rem',
          background: `linear-gradient(0deg, rgba(245, 245, 245, 0) 0%, ${Colors.shadowWhite} 90%)`,
          pointerEvents: 'none',
          zIndex: 4
        }}
      />

      <Box
        sx={{
          zIndex: 1,
          width: '100%',
          height: '40%',
          overflowY: 'auto',
          paddingX: '1.5rem',
          fontSize: isLargeScreen ? '1.875rem' : '1rem',
          fontFamily: 'Nunito',
          fontWeight: 500,
          textAlign: 'center',
          color: Colors.green.main,
          '&::-webkit-scrollbar': {
            width: '8px',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: Colors.grey,
            borderRadius: '60px',
            width: '2px'
          }
        }}>
        <ReactMarkdown>{lastMessage?.text}</ReactMarkdown>
        <div ref={chatEndRef} />
      </Box>

      <Box
        sx={{
          width: '100%',
          height: '40%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center'
        }}>
        {isRecording && (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
              alignItems: 'center'
            }}>
            <Typography
              sx={{
                background: 'linear-gradient(180deg, #293937 0%, #729F99 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                textFillColor: 'transparent',
                fontSize: isLargeScreen ? '1.875rem' : '1.25rem',
                fontFamily: 'Nunito',
                fontWeight: 500,
                textAlign: 'center',
                paddingBottom: isLargeScreen ? '6rem' : '3rem',
                paddingX: '2.5rem'
              }}>
              Try describing a recent event or setting a goal
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AudioChatBox;
