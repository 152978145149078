import { useSelector } from 'react-redux';

import { Box, SxProps, Theme } from '@mui/material';

import assistant from '../../../assets/images/AvatarChat/assistant.png';
import { chatSelector } from '../../../redux/chat/chat.selector';
import AvatarAudioDots from './AvatarAudioDots';

interface StatusAvatarPictureProps {
  sx?: SxProps<Theme>;
}

const StatusAvatarPicture = ({ sx }: StatusAvatarPictureProps) => {
  const { mode, isRecording } = useSelector(chatSelector);

  return (
    <Box sx={{ position: 'relative', width: 80, height: 80, ...sx }}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        component={'img'}
        src={assistant}
        alt="Avatar assistant"
      />
      {mode === 'voice' && !isRecording && (
        <AvatarAudioDots sx={{ position: 'absolute', bottom: 0, right: 0 }} />
      )}
    </Box>
  );
};

export default StatusAvatarPicture;
