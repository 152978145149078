import { io } from 'socket.io-client';

import { getLocalStorage } from '../utils/localstorage';

const token = getLocalStorage('token');

const socket = io(`${process.env.REACT_APP_SOCKET_GATEWAY}`, {
  auth: { token: token },
  withCredentials: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000
});

export default socket;
