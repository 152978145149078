export enum Voices {
  iAffirm = 'iAffirm',
  Benajmin = 'Benajmin',
  Kaine = 'Kaine',
  Adam = 'Adam',
  Francesca = 'Francesca',
  Ana = 'Ana',
  Jaxon = 'Jaxon',
  Jack = 'Jack',
  Serenity = 'Serenity',
  Brittney = 'Brittney',
  Danielle = 'Danielle'
}
