import { useSelector } from 'react-redux';

import { Box, Grow } from '@mui/material';

import { chatSelector } from '../../../redux/chat/chat.selector';
import ChatInputText from './ChatInputText';
import ChatInputVoice from './ChatInputVoice';

const ChatInputWrapper = ({
  onSend,
  disabled
}: {
  onSend: (message: string) => void;
  disabled: boolean;
}) => {
  const { mode } = useSelector(chatSelector);

  return (
    <Box sx={{ width: '100%' }}>
      {mode === 'text' && (
        <Grow in={mode === 'text'} timeout={250}>
          <Box>
            <ChatInputText onSend={onSend} disabled={disabled} />
          </Box>
        </Grow>
      )}

      {mode === 'voice' && (
        <Grow in={mode === 'voice'} timeout={250}>
          <Box>
            <ChatInputVoice disabled={disabled} />
          </Box>
        </Grow>
      )}
    </Box>
  );
};

export default ChatInputWrapper;
