import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, SxProps, Theme } from '@mui/material';

import { Colors } from '../../../design/theme';

interface AvatarAudioDotsProps {
  sx?: SxProps<Theme>;
}

const AvatarAudioDots = ({ sx }: AvatarAudioDotsProps) => {
  // TODO will probably have some logic to do some anymation when audio starts streaming

  return (
    <Box
      sx={{
        width: '2rem',
        height: '2rem',
        background: Colors.primary.main,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}>
      <MoreHorizIcon sx={{ color: Colors.white }} />
    </Box>
  );
};

export default AvatarAudioDots;
