import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChatMessage } from '../../modules/avatar/types/chat-message.type';
import { ChatState } from './types/chat-state.interface';

const initialState: ChatState = {
  messages: [],
  mode: 'text',
  isConnected: false,
  isError: false,
  isAnswering: false,
  isRecording: false
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.messages = action.payload;
    },
    addMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages.push(action.payload);
    },
    addIncomingMessage: (
      state,
      action: PayloadAction<{ text: string; id: string }>
    ) => {
      const lastMessage = state.messages[state.messages.length - 1];

      if (
        lastMessage &&
        lastMessage.from === 'ai' &&
        lastMessage.id === action.payload.id
      ) {
        lastMessage.text += action.payload.text;
      } else {
        state.messages.push({
          from: 'ai',
          text: action.payload.text,
          id: action.payload.id
        });
      }
    },
    setMode: (state, action: PayloadAction<'text' | 'voice'>) => {
      state.mode = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    setIsAnswering: (state, action: PayloadAction<boolean>) => {
      state.isAnswering = action.payload;
    },
    setIsRecording: (state, action: PayloadAction<boolean>) => {
      state.isRecording = action.payload;
    }
  }
});

export const {
  setMessages,
  addMessage,
  setMode,
  setIsConnected,
  setIsError,
  setIsAnswering,
  addIncomingMessage,
  setIsRecording
} = chatSlice.actions;

export default chatSlice.reducer;
