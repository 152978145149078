import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { chatSelector } from '../../../redux/chat/chat.selector';
import AudioChatBox from './AudioChatBox';
import TextChatBox from './TextChatBox';

const ChatContainer = () => {
  const { mode } = useSelector(chatSelector);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto'
      }}>
      {mode === 'text' && <TextChatBox />}
      {mode === 'voice' && <AudioChatBox />}
    </Box>
  );
};

export default ChatContainer;
