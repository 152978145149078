enum RoutesEnum {
  HOLDING = '/',
  HOME = '/home',
  LOGIN = '/login',
  SIGNUP = '/signup',
  ONBOARDING = '/user-onboarding',
  NEW_AFFIRMATION = '/user/new-affirmation',
  VISUALIZATION = '/user/visualization',
  VISUALIZATION_VIEW = '/user/visualization/',
  NEW_MEDITATION = '/user/new-meditation',
  MEDITATION_VIEW = '/user/meditation/',
  EDIT_MEDITATION = '/user/edit-meditation/',
  EDIT_VISUALIZATION = '/user/edit-visualization/',
  USER = '/user',
  USER_PROFILE = '/user/profile',
  USER_HOME = '/user/',
  CHANGE_PASSWORD = '/user/change-password',
  RESTORE_PASSWORD = '/restore-password',
  RESTORE_REQUEST = '/request-password',
  TERMS_CONDITIONS = '/terms-conditions',
  AVATAR = '/user/avatar'
}
export default RoutesEnum;
