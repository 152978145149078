import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Container, Divider, Typography, alpha } from '@mui/material';

import { Colors } from '../design/theme';
import { useAppSelector } from '../hooks/useAppSelector';
import useIsLargeScreen from '../hooks/useIsLargeScreen';
import AvatarAssistant from '../modules/avatar/components/AvatarAssistant';
import ChatContainer from '../modules/avatar/components/ChatContainer';
import ChatInputWrapper from '../modules/avatar/components/ChatInputWrapper';
import { ChatEvent } from '../modules/avatar/enums/ChatEvent.enum';
import { ChatMessage } from '../modules/avatar/types/chat-message.type';
import { chatSelector } from '../redux/chat/chat.selector';
import {
  addIncomingMessage,
  addMessage,
  setIsAnswering,
  setIsConnected,
  setIsError,
  setMessages
} from '../redux/chat/chat.slice';
import { userSelector } from '../redux/user/user.selector';
import socket from '../services/socket';

const Avatar = () => {
  const dispatch = useDispatch();
  const isLargeScreen = useIsLargeScreen();
  const originalUserInfo = useAppSelector(userSelector).ui.userInfo;
  const { messages, isConnected, isError, isAnswering, mode, isRecording } =
    useSelector(chatSelector);

  useEffect(() => {
    if (messages.length === 0 && originalUserInfo) {
      dispatch(
        setMessages([
          {
            id: 'initial-message',
            from: 'ai',
            text: `Hi ${
              originalUserInfo?.firstName || 'there'
            }! To tailor your affirmations for the day, I’d like to ask: What would you like to work on? Take a moment to reflect on what feels most important right now.`
          }
        ])
      );
    }
  }, [messages.length, originalUserInfo]);

  const onConnect = () => {
    dispatch(setIsConnected(true));
    dispatch(setIsError(false));
  };
  const onDisconnect = () => dispatch(setIsConnected(false));

  useEffect(() => {
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    socket.on(ChatEvent.START, () => {
      dispatch(setIsAnswering(true));
    });

    socket.on(ChatEvent.MESSAGE, (e: ChatMessage) => {
      if (e.text !== undefined && e.text !== null) {
        dispatch(addIncomingMessage({ id: e.id || '', text: e.text }));
      } else if (e?.text === undefined) {
        dispatch(setIsAnswering(false));
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSend = (messageText: string) => {
    if (!isConnected) return;

    socket.emit(ChatEvent.USER_MESSAGE, messageText);

    // TODO see how to handle message and the storing of user messages
    dispatch(
      addMessage({ id: 'user-message', from: 'user', text: messageText })
    );
  };

  return (
    <Container sx={{ justifyContent: 'center', paddingX: 0 }}>
      <Box
        sx={{
          height: originalUserInfo
            ? 'calc(100dvh - 4rem)'
            : 'calc(100dvh - 7.75rem)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: '51.25rem',
          paddingBottom: '2.5rem'
        }}>
        <AvatarAssistant />

        {isLargeScreen && (
          <Divider
            sx={{
              width: '100%'
            }}
          />
        )}

        <ChatContainer />

        {isError && (
          <Typography sx={{ marginBottom: '1rem', color: Colors.Bazaar }}>
            Something went wrong, please try again
          </Typography>
        )}

        <Box
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: '0.25rem',
            zIndex: 10
          }}>
          {mode === 'text' && (
            <Box
              sx={{
                position: 'absolute',
                top: !isLargeScreen ? '-25px' : '-35px',
                left: 0,
                right: 0,
                height: '50%',
                background: `linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, ${Colors.shadowWhite} 90%)`,
                zIndex: 0
              }}
            />
          )}

          <ChatInputWrapper
            onSend={handleSend}
            disabled={!isConnected || isAnswering}
          />
        </Box>

        {mode === 'voice' && isRecording && (
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '14.875rem',
              background: `linear-gradient(to top, ${alpha(
                Colors.primary.main,
                0.33
              )} 0%, rgba(0, 0, 0, 0) 100%)`,
              zIndex: 1
            }}
          />
        )}
      </Box>
    </Container>
  );
};

export default Avatar;
