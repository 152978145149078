import { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { Box, Paper, Typography } from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import StatusAvatarPicture from './StatusAvatarPicture';

const TextChatBox = () => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useIsLargeScreen();
  const { messages } = useSelector(chatSelector);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView();
  }, [messages]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '1rem',
        paddingTop: '2rem',
        paddingBottom: '1rem',
        paddingX: !isLargeScreen ? '1rem' : '0.5rem',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          scrollbarWidth: 'none',
          width: '8px',
          borderRadius: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.grey,
          borderRadius: '60px',
          width: '2px'
        }
      }}>
      {!isLargeScreen && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '1rem'
          }}>
          <StatusAvatarPicture />
        </Box>
      )}
      {messages.map((message, index) => (
        <Box
          key={index} // TODO change to message.id
          sx={{
            display: 'flex',
            justifyContent: message.from === 'user' ? 'flex-end' : 'flex-start',
            maxWidth: '100%',
            ...(!isLargeScreen && {
              marginLeft: message.from === 'user' ? '3rem' : '0'
            }),
            ...(!isLargeScreen && {
              marginRight: message.from === 'user' ? '0' : '3rem'
            })
          }}>
          <Paper
            elevation={0}
            sx={{
              maxWidth: '33.5rem',
              paddingX: '1rem',

              borderRadius:
                message.from === 'user'
                  ? '1rem 1rem 0 1rem'
                  : '1rem 1rem 1rem 0',
              backgroundColor:
                message.from === 'user' ? Colors.green.main : Colors.greyWhite,
              color: message.from === 'user' ? Colors.white : Colors.black,
              fontSize: '1rem',
              fontFamily: 'Nunito',
              fontWeight: 500,
              width: 'auto'
            }}>
            {message.from === 'user' && (
              <Typography
                sx={{
                  paddingY: '1rem',
                  fontSize: '1rem',
                  fontFamily: 'Nunito',
                  fontWeight: 500,
                  width: 'auto',
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}>
                {message.text}
              </Typography>
            )}
            {message.from === 'ai' && (
              <ReactMarkdown>{message.text}</ReactMarkdown>
            )}
          </Paper>
        </Box>
      ))}

      <div ref={chatEndRef} />
    </Box>
  );
};

export default TextChatBox;
